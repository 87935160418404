/* mirador-custom.css */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

#mirador-container {
  position: relative;
  width: 100%;
  height: 600px; /* Set the desired height */
  margin-top: 40px;
  overflow: hidden;
}

.Connect\(WithPlugins\(Workspace\)\)-workspaceWithControlPanel-21 {
  padding-left: 0px !important; /* since the left navbar is hidden I don't need the padding */
}

.mirador-scrollto-scrollable {
  scrollbar-color: #2d3439 #575d62;
}

/* Hide left navbar with Mirador logo */
/* nav.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionAbsolute.MuiAppBar-colorDefault.WithPlugins\(WorkspaceControlPanel\)-root-5.mirador-workspace-control-panel {
  display: none;
} */
